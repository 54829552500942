:root {
  --amplify-primary-color: #2693ff;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
  --amplify-font-family: 'Roboto', ‘Helvetica’, ‘Helvetica Neue Light’, ‘Helvetica Neue’, ‘Arial’, ‘sans-serif’
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.fill-svg {
  position: absolute;
  bottom:-1px;
  left:0;
  background-color:transparent;
  fill:rgb(1, 49, 83);
}


p {
  word-wrap: normal;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content {
  padding-top:30px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  min-width: 400px;
}

.topBarMenu {
  background-color: #202F3F !important; 
  position: sticky;
  top:0;
  margin:0 !important;
  z-index: 1;
  height:65px;
 }

 
 .ui.button.homeButton {
  font-weight: 300 !important;
  color:white;
  background-color: transparent !important;
  padding-bottom: 12px !important;
}

.ui.button.homeButton:hover {
  color:white;
  /* font-weight: 500; */
  transform: scale(1.2);
  /* font-size: 1.1em !important; */
  /* text-decoration: underline; */
}

.ui.button.homeButtonSignup {
  font-weight: 300 !important;
  color:white;
  background-color: #59d759 !important;
}

.ui.button.homeButtonSignup:hover {
  color:white;
  /* font-weight: 500; */
  transform: scale(1.2);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.getStartedButton {
  margin-bottom:200px !important;
  background-color: #4DC6E7 !important;
  font-size: 1.8em !important;  
  
}


.getStartedButton:hover {
  transform: scale(1.2);
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}


.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
}


.fadeOut {
  -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 1s; /* Firefox < 16 */
   -ms-animation: fadeout 1s; /* Internet Explorer */
    -o-animation: fadeout 1s; /* Opera < 12.1 */
       animation: fadeout 1s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
       animation-delay: 0.5s;
}

.menuList {
  background-image:linear-gradient(to bottom right, #2D3238 , #424545) !important;
}